var site = window.site || {};
var prodcat = window.prodcat || {};
var generic = window.generic || {};

prodcat.data = prodcat.data || {};
site.template = site.template || {};
site.translations = site.translations || {};
site.translations.product = site.translations.product || {};

(function ($) {
  Drupal.behaviors.ProductRecommendationsV1 = {
    attached: false,
    isMobileStacked: false,
    isMobile: false,
    unison: {},
    priceThreshold: 0,
    nodes: {},

    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.init(context);
      self.attached = true;
      self.unison = Unison.fetch;
      self.isMobile = parseInt(self.unison.now().width, 10)
                    < parseInt(self.unison.all().medium, 10);
    },

    selectors: {
      container: '.js-product-recommendations',
      carousel: '.js-product-grid__content'
    },

    /* eslint-disable id-length */

    init: function (context) {
      var self = this;
      var placementId;
      var eventType;
      var resultsCount;
      var googleFilter;
      var useNGGlobal;
      var dryRun;
      var productId;

      self.nodes.$container = $(self.selectors.container, context);
      placementId = self.nodes.$container.data('placement-id');
      eventType = self.nodes.$container.data('event-type');
      resultsCount = self.nodes.$container.data('results-count') < 100 ? self.nodes.$container.data('results-count') : 100;
      googleFilter = self.nodes.$container.data('filter');
      useNGGlobal = self.nodes.$container.data('use-ngglobal');
      dryRun = self.nodes.$container.data('dry-run');
      self.isMobileStacked = self.nodes.$container.data('is-mobile-stacked');
      self.priceThreshold = self.nodes.$container.data('price-threshold') || 0;

      productId =
        page_data &&
        page_data['catalog-spp'] &&
        page_data['catalog-spp'].products &&
        page_data['catalog-spp'].products[0]
          ? page_data['catalog-spp'].products[0].PRODUCT_ID
          : '';

      dryRun = (!!self.nodes.$container.data('dry-run')).toString();

      self.loadRecommendations(
        placementId,
        eventType,
        resultsCount,
        googleFilter,
        useNGGlobal,
        dryRun,
        productId
      );
    },

    loadRecommendations: function (
      placementId,
      eventType,
      resultsCount,
      googleFilter,
      useNGGlobal,
      dryRun,
      productId
    ) {
      var self = this;
      var params = self.buildDataObject(
        placementId,
        eventType,
        resultsCount,
        googleFilter,
        useNGGlobal,
        dryRun,
        productId
      );

      self.getRecommendations(params);
    },

    buildDataObject: function (
      placementId,
      eventType,
      resultsCount,
      googleFilter,
      useNGGlobal,
      dryRun,
      productId
    ) {
      return {
        useNGGlobal: useNGGlobal,
        dryRun: dryRun,
        eventType: eventType,
        placementId: placementId,
        pageSize: resultsCount,
        filter: googleFilter,
        productId: productId
      };
    },

    getRecommendations: function (params) {
      var self = this;
      var data;

      generic.jsonrpc.fetch({
        method: 'user.googleAIRecommend',
        params: [params],
        onSuccess: (response) => {
          data = JSON.parse(response.getValue());

          if (data && data.results && data.results.length !== 0) {
            self.processResponse(data);
          } else {
            self.nodes.$container.toggleClass('hidden');
          }
        }
      });
    },

    processResponse: function (data) {
      var self = this;
      var items = [];
      var recommendationToken = data.recommendationToken;

      data.results.forEach((result) => {
        items.push(result.id);
      });
      self.getPerlgemProductData(items, recommendationToken);
      if (site.trackingDataLayer) {
        site.trackingDataLayer.update({
          aiRecommendationToken: recommendationToken
        });
      }
    },

    getPerlgemProductData: function (results, token) {
      var self = this;
      var queryParams;

      results.forEach((productId, index) => {
        results[index] = productId.indexOf('PROD') !== -1 ? productId : 'PROD' + productId;
      });

      /* eslint-disable camelcase */
      queryParams = [
        {
          method: 'prodcat.querykey',
          params: [
            {
              products: results,
              query_key: 'catalog-mpp'
            }
          ]
        }
      ];

      $.ajax({
        url: '/rpc/jsonrpc.tmpl',
        type: 'post',
        data: $.param({ JSONRPC: JSON.stringify(queryParams) })
      }).done((response) => {
        var products = _.compact(response[0].result.value.products);
        var rendered;
        var $productBriefs;
        var prodData = [];
        var productObj = [];
        var productsInventoryCheck = [];
        var productsAvailable = [];

        productObj.products = [];
        if (response.length > 0 && response[0].result.value) {
          $(products).each(function (index, product) {
            $(product['skus']).each(function (index, sku) {
              // Filter soldout skus (inventory status 7) AND products priced below threshold
              if (sku['INVENTORY_STATUS'] !== 7 && sku['PRICE'] >= self.priceThreshold) {
                productsInventoryCheck.push(product);
              }
            });
          });
          productsAvailable = _.uniq(productsInventoryCheck);
          prodcat.data.updateProducts(productsAvailable);
          productsAvailable.forEach((product) => {
            prodData.push(prodcat.data.getProduct(product.PRODUCT_ID));
          });
          productObj.products = prodData;
          productObj.hide_flag = false;
          productObj.display_flag_v2 = true;
          rendered = site.template.get({
            name: 'mpp_product_grid',
            data: productObj
          });

          self.nodes.$container.find('.js-product-recommendations__grid').append(rendered).show();
          self.setCarousel();
          Drupal.behaviors.selectBox.attach();
          Drupal.behaviors.skuListSizesV1.attach();
          Drupal.behaviors.productBriefShadesV1.attach();
          $('.js-product-pr', self.nodes.$container).trigger('product.init-pr');
          $(window).trigger('power-reviews-render');

          $productBriefs = $('.js-grid-item', self.nodes.$container);
          if ($productBriefs.length > 0) {
            $('.js-spp-link', $productBriefs).each(function (index, link) {
              $(link).attr('href', function (item, url) {
                if (url.indexOf('rtoken') === -1) {
                  return url + '/?rtoken=' + token;
                }
              });
            });
          }
        }
      });
    },

    setCarousel: function () {
      var self = this;
      var $carousels = $(self.selectors.carousel, self.nodes.$container);
      var $carouselItem = $('.js-grid-item', $carousels);
      var initSlider = $carouselItem.length > 1;
      var $arrowsDiv = self.nodes.$container.find('.carousel-controls');
      var settings;
      var contentWidth = Unison.fetch.all();
      var mobileWidth = parseInt(contentWidth.xsmall, 10);
      var ipadWidth = parseInt(contentWidth.small, 10);
      var pcWidth = parseInt(contentWidth.landscape, 10);
      var slideCount = $carouselItem.length > 3 ? 4 : 3;
      var slidesSettings = self.nodes.$container.data() || {};
      var nextLabelDefault =
        site.translations.product && site.translations.product.next_slide
          ? site.translations.product.next_slide
          : 'next';
      var previousLabelDefault =
        site.translations.product && site.translations.product.previous_slide
          ? site.translations.product.previous_slide
          : 'previous';
      var previousLabel = slidesSettings.previousLabel || previousLabelDefault;
      var arrowIcon = 'arrow';
      var nextLabel = slidesSettings.nextLabel || nextLabelDefault;
      var prevArrow =
        '<div class="slick-prev-button slick--custom"><svg role="img" aria-label="' +
        previousLabel +
        '" class="icon icon--' +
        arrowIcon +
        '--left slick-prev"><use xlink:href="#' +
        arrowIcon +
        '--left"></use></svg></div>';
      var nextArrow =
        '<div class="slick-next-button slick--custom"><svg role="img" aria-label="' +
        nextLabel +
        '" class="icon icon--' +
        arrowIcon +
        '--right slick-next"><use xlink:href="#' +
        arrowIcon +
        '--right"></use></svg></div>';

      if (!initSlider) {
        return;
      }
      settings = {
        infinite: true,
        mobileFirst: true,
        arrows: true,
        responsive: [
          {
            breakpoint: pcWidth,
            settings: {
              slidesToShow: Math.min(slideCount, $carouselItem.length),
              slidesToScroll: 1,
              appendArrows: $arrowsDiv,
              prevArrow: prevArrow,
              nextArrow: nextArrow
            }
          },
          {
            breakpoint: ipadWidth,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              appendArrows: $arrowsDiv,
              prevArrow: prevArrow,
              nextArrow: nextArrow
            }
          },
          {
            breakpoint: mobileWidth,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

      if (initSlider) {
        $carousels.slick(settings);
      }

      if (self.isMobile === true && self.isMobileStacked === true) {
        $carousels.slick('destroy');
      }

      Unison.on('change', function () {
        self.isMobile = parseInt(self.unison.now().width, 10)
                      < parseInt(self.unison.all().medium, 10);

        if (self.isMobile === true && self.isMobileStacked === true) {
          $carousels.slick('destroy');
        } else {
          if (!$carousels.hasClass('slick-initialized')) {
            $carousels.slick(settings);
          }
        }
      });
    }
  };
})(jQuery);
